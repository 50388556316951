<template>
  <div :class="isScreenGalerie ? 'galeriescreen' : 'gelerieglobal'">
    <div class="d-flex justify-content-between" style="margin-bottom: 15px">
      <div class="d-flex galerie_title">
        <h3>{{ $t("your_gallery") }}</h3>
      </div>

      <div>
        <!--button class="btn btn-light btn-sm" @click="close">{{ $t("close") }}</button-->
        <!--div @click="close" class="close_icon">
                <span class="material-symbols-outlined">close</span>
              </div-->
      </div>
    </div>

    <!--div>
            <button @click="openGalerie">Galerie</button>
            <button @click="openUpload">Upload</button>
          </div-->
    <CRow class="row_gallery">
      <CCol lg="12" class="galerieMedia" v-show="galerieMedia">
        <div>
        <div class="form-group mt-2 mb-5">
          <!--label for="categories">{{ $t("filter_cat") }}</label-->
          <div>
            <div class="category">
              <span class="material-symbols-outlined"> tune </span>

              <select
                id="categories"
                name="category"
                class="form-control"
                v-model="filtreCat"
              >
                <option value="">{{ $t("all_cats") }}</option>
                <option
                  v-for="c in mediaCategories"
                  :key="c.id"
                  v-bind:value="c.id"
                >
                  {{ c.name }}
                </option>
              </select>
              <button
                class="btn btn-light"
                @click="showTextCat = !showTextCat"
                type="button"
              >
                <i class="small material-icons">add_circle</i>
                {{ this.$t("new_cat") }}
              </button>
            </div>
            <div v-show="showTextCat" class="newC">
              <input
                :placeholder="this.$t('name_cat')"
                v-model="newCat"
                class="form-control"
              />
              <button class="btn btn-primary" type="button" @click="addCat">
                {{ this.$t("add") }}
              </button>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <h5 v-if="isScreenGalerie">{{ $t("select_media_to_add") }}</h5>
            <h5 v-else>{{ $t("vos_medias") }}</h5>
            <label id="upload-button">
              <span class="material-symbols-outlined">file_upload</span
              >{{ messageUplaod }}
              <input
                type="file"
                multiple
                @change="onInputChange"
                accept="image/png, image/jpg, image/jpeg, video/mp4"
              />
            </label>
          </div>
          <CProgress
            :value="uploadPercentage"
            color="success"
            animated
            show-value
            style="height: 15px"
            class="mt-1"
            v-if="uploadPercentage > 0"
          />
        </div>

        <div v-if="isScreenGalerie" class="mb-2 mt-4">
          <div
            v-if="isSelectAll"
            @click="selectAll()"
            style="display: inline-block"
          >
            <span class="selectAll">
              <span class="material-symbols-outlined iconeSelect"
                >check_box_outline_blank</span
              >
              <span class="txtSelectAll">{{ $t("select_all") }}</span>
            </span>
          </div>
          <div v-else @click="unselectAll" style="display: inline-block">
            <span class="selectAll">
              <span class="material-symbols-outlined iconeSelect"
                >check_box</span
              >
              <span class="txtSelectAll">{{ $t("deselect_all") }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div v-for="m in filteredMedias" :key="m.id" class="col-lg-2 blockM">
            <div>
              <!-- v-if="filtreCat == '' || m.categorymedia_id == filtreCat" -->
              <div
                :class="
                  isScreenGalerie &&
                  m.screens.some((item) => item.id == screen_id)
                    ? 'desactivate'
                    : ''
                "
              >
                <div
                  class="inBlockM"
                  :class="
                    isScreenGalerie && selectedMedias.includes(m.id)
                      ? 'active'
                      : ''
                  "
                >
                  <label>
                    <div v-if="m.type == 'picture'">
                      <div class="file__thumbnail">
                        <div
                          v-bind:style="{
                            backgroundImage:
                              'url(\'' + urlMedia(m.thumb) + '\'',
                          }"
                          class="img_media"
                        ></div>
                        <!--img
                        :src="urlMedia(m.thumb)"
                        class="thumbnail__thumbnail"
                      /-->
                      </div>
                    </div>
                    <div v-else>
                      <div style="text-align: center">
                        <!-- preload="metadata" -->
                        <video
                          width="100%"
                          controls="controls"
                          preload="none"
                          :poster="urlMedia(m.thumb)"
                        >
                          <source
                            :src="urlMedia(m.file_name)"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                    <span
                      v-if="
                        isScreenGalerie &&
                        !m.screens.some((item) => item.id == screen_id)
                      "
                    >
                      <input
                        type="checkbox"
                        class="media_checkbox"
                        :id="'chk_' + m.id"
                        :value="m.id"
                        v-model="selectedMedias"
                        @click="selectM(m.id)"
                      />
                      <p class="bSelect">
                        <span v-if="!selectedMedias.includes(m.id)">
                          {{ $t("select") }}
                        </span>
                        <span v-else>{{ $t("unselect") }}</span>
                      </p>
                    </span>
                    <span
                      v-else-if="
                        isScreenGalerie &&
                        m.screens.some((item) => item.id == screen_id)
                      "
                    >
                      <p class="bSelect mt-4">
                        <span>{{ $t("select") }}</span>
                      </p>
                    </span>
                  </label>
                  <!--button type="button" class="btn btn-secondary" @click="selectMedia(m.id)">Selectionner</button-->
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="deleteMedia(m.id)"
                  >
                    {{ $t("delete") }}
                  </button>
                  <div class="d-flex cat_line">
                    <div>{{ $t("category") }}</div>
                    <select
                      @change="catSelection($event, m.id)"
                      v-model="m.categorymedia_id"
                      class="form-control"
                    >
                      <option
                        v-for="c in mediaCategories"
                        :key="c.id"
                        v-bind:value="c.id"
                      >
                        {{ c.name }}
                      </option>
                    </select>
                  </div>
                  <div class="mt-3" style="font-size: 12px; text-align: right">
                    {{ (m.size / 1000000).toFixed(2) }} Mb
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> 
      

        <div v-if="isScreenGalerie" class="footerAdd">
          <div v-if="!loadingAddMedia" class="text-right">
            <button
              class="btn btn-primary"
              @click="addSelection"
              :disabled="selectedMedias.length == 0"
            >
              {{ $t("add_to_the_screen") }}
            </button>
          </div>
          <div v-else class="text-right">
            <button class="btn btn-primary" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Saving...
            </button>
          </div>
        </div>

        <div v-if="error_msgs.length > 0" class="error_msg">
          <div
            class="alert alert-danger"
            role="alert"
            v-for="err in error_msgs"
            :key="err"
          >
            {{ $t(err) }}
          </div>
        </div>
      
      </CCol>
      <CCol lg="12" class="galerieUpload" v-show="galerieUpload">
        <input type="file" id="file-input" multiple @change="onInputChange" />
        <div id="file-drag-drop">
          <form ref="fileform">
            <span class="drop-files">{{ $t("deposer_fichiers") }}</span>

            <!--progress
                        max="100"
                        :value.prop="uploadPercentage"
                      ></progress-->
          </form>
          <!-- CPRGORESSE HERE -->
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
//import {Cloudinary} from 'cloudinary-core';

export default {
  name: "Galerie",

  props: {
    screen_id: Number,
  },
  data: function () {
    return {
      galerie: [],
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      selectedMedias: [],
      loadingAddMedia: false,
      remain_photos: 0,
      remain_videos: 0,
      error_msgs: [],
      galerieMedia: true,
      galerieUpload: false,
      isScreenGalerie: true,
      mediaCategories: [],
      newCat: "",
      showTextCat: false,
      filtreCat: "",
      isSelectAll: true,
    };
  },
  async created() {
    if (this.screen_id == 0) this.isScreenGalerie = false;
    else this.isScreenGalerie = true;

    this.loadMediaCategories();
    this.loadMediaScreen();
  },
  computed: {
    // a computed getter
    messageUplaod: function () {
      // `this` points to the vm instance
      if (this.filtreCat == "") return this.$t("add_new_files");
      else return this.$t("add_new_files_cat");
    },
    filteredMedias: function () {
      if (this.filtreCat == "") return this.galerie;
      else
        return this.galerie.filter(
          (media) => media.categorymedia_id == this.filtreCat
        );
    },
  },
  methods: {
    async catSelection(event, media_id) {
      await window.axios.post("/api/medias/" + media_id + "/tocategory", {
        cat_id: event.target.value,
      });
    },
    async loadMediaCategories() {
      let rep = await window.axios.get("/api/medias/category");
      this.mediaCategories = [
        { id: null, name: this.$t("no_cat") },
        ...rep.data.categorymedias,
      ];
      console.log(this.mediaCategories);
    },
    async loadMediaScreen() {
      let rep = null;
      if (this.isScreenGalerie)
        rep = await window.axios.get(
          "/api/medias/screen/" + this.screen_id + "/m"
        );
      else rep = await window.axios.get("/api/medias");

      this.galerie = rep.data.medias;
      // this.remain_photos = rep.data.remain_photos;
      // this.remain_videos = rep.data.remain_videos;
      console.log("here -> " + this.screen_id, this.galerie);
    },
    async addCat() {
      let rep = await window.axios.post("/api/medias/category", {
        name: this.newCat,
      });
      this.mediaCategories.push(rep.data.categorymedia);
      this.newCat = "";
      this.showTextCat = false;
      this.filtreCat = rep.data.categorymedia.id;

      this.$notify({
        group: "foo",
        title: "BizScreen",
        text: "ajouté avec succés",
        type: "success",
      });
    },
    selectAll() {
      /* for(const v in this.galerie){
        //this.selectedMedias.push(v.id)
        console.log(v)
      }*/
      const self = this;
      this.selectedMedias = [];
      this.galerie.forEach(function (m) {
        if (
          (self.filtreCat == "" || m.categorymedia_id == self.filtreCat) &&
          !m.screens.some((item) => item.id == self.screen_id)
        )
          self.selectedMedias.push(m.id);
      });
      this.isSelectAll = false;
    },
    unselectAll() {
      this.selectedMedias = [];
      this.isSelectAll = true;
    },
    selectM(value) {
      console.log("click", value);
      console.log(this.selectedMedias);
      // event.preventDefault()
    },
    selectMedia(id) {
      document.getElementById("chk_" + id).checked = document.getElementById(
        "chk_" + id
      ).checked
        ? false
        : true;
    },
    urlMedia(url) {
      return process.env.VUE_APP_MEDIA_URL + url;
    },
    close() {
      this.$emit("closeGalerie", false);
    },
    async addSelection() {
      //console.log(this.selectedMedias);
      this.loadingAddMedia = true;
      try {
        const rep = await window.axios.post(
          "/api/mediascreen/" + this.screen_id,
          {
            medias: this.selectedMedias,
          }
        );
        //console.log(rep)
        this.error_msgs = rep.data.error_msg;
        //setTimeout(() => this.error_msgs = [], 2000)
      } catch (err) {
        console.log("erreur add media");
        this.$notify({
          group: "foo",
          title: "BizScreen",
          text: "error",
          type: "error",
        });
      } finally {
        this.loadingAddMedia = false;
        if (this.error_msgs.length == 0) {
          this.$notify({
            group: "foo",
            title: "BizScreen",
            text: this.$t("add_to_screen"),
            type: "success",
          });
        }
        this.selectedMedias = [];
        this.loadMediaScreen();
        this.close();
      }
    },
    toggleMedia() {
      //console.log(this.selectedMedias)
    },
    openGalerie() {
      this.galerieMedia = true;
      this.galerieUpload = false;
    },
    openUpload() {
      this.galerieMedia = false;
      this.galerieUpload = true;
    },
    deleteMedia(id) {
      const self = this;
      window.axios.delete("/api/medias/" + id).then(() => {
        console.log("deleted");
        /*window.axios.get("/api/medias/screen/"+self.screen_id).then((rep)=>{
            self.galerie = rep.data.medias;
          })*/
        self.loadMediaScreen();
      });
    },
    onInputChange(e) {
      console.log(e);
      for (let i = 0; i < e.target.files.length; i++) {
        if (
          e.target.files[i].type == "image/jpeg" ||
          e.target.files[i].type == "image/png" ||
          e.target.files[i].type == "video/mp4" /*||
              e.dataTransfer.files[i].type == "image/avi" ||
              e.dataTransfer.files[i].type == "video/avi"*/
        )
          this.files.push(e.target.files[i]);
      }
      console.log(this.files);
      this.submitFiles();

      /* this.files = e.target.files
        e.target.value = null
        this.submitFiles()*/
    },
    submitFiles() {
      /*
          Initialize the form data
        */
      let formData = new FormData();
      let self = this;

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append("files[" + i + "]", file);
      }
      formData.append("screen_id", self.screen_id);
      formData.append("cat_id", self.filtreCat);
      /*
          Make the request to the POST /file-drag-drop-instant URL
        */
      window.axios
        .post("/api/medias", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this),
        })
        .then(function (rep) {
          console.log("SUCCESS!!");
          console.log(rep);
          self.galerie = null;
          self.galerie = rep.data.medias;
        })
        .catch(function () {
          self.$notify({
            group: "foo",
            title: "BizScreen",
            text: "Error",
            type: "error",
          });
        })
        .finally(() => {
          self.files = [];
          self.uploadPercentage = 0;
        });
    },
  },
};
</script>

<style scoped>
#file-drag-drop form {
  display: block;
  height: 400px;

  background: rgb(233, 230, 230);
  margin: auto;
  margin-top: 40px;
  text-align: center;
  line-height: 400px;
  border-radius: 4px;
  border-radius: 4px;
  border: 3px #3c4b64 dashed;
}
#file-drag-drop form:hover {
  border: 3px #3ab5af dashed;
}

progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.file__thumbnail {
  /*height: 150px;*/
  overflow: hidden;
  display: flex;
}

.thumbnail__thumbnail {
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

input[type="checkbox"] {
  visibility: hidden;
}

img.active,
video.active,
.inBlockM.active {
  border: 5px solid rgb(65 183 65 / 80%);
  border-radius: 5px;
}

img:hover {
  cursor: pointer;
}

.blockM {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 35px;
}

.error_msg {
  text-align: left;
  margin-top: 8px;
}

.blockM {
  /*background-color: #ebedef;
    padding: 10px;
    border-radius: 5px;*/
  display: flex;
}
.inBlockM {
  background-color: #ebedef;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
}

.bSelect {
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #257cff !important;
  border-color: #257cff !important;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-bottom: 0;
}

.bSelect:hover {
  background-color: #f45a2a !important;
  border-color: #f45a2a !important;
}

.blockM label {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.galerie_title {
  gap: 20px;
  align-items: center;
}

.galerie_title h3 {
  margin-bottom: 0 !important;
}
/*
.galerie_title button{
  font-size: 0.90rem;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
}*/

#upload-button input {
  display: none;
}
#upload-button {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  font-weight: 400;

  line-height: 1.5;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 0;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-top: 5px;
  padding-bottom: 5px;
}
#upload-button:hover {
  color: #fff;
  background-color: #0d86ff;
  border-color: #0080ff;
  cursor: pointer;
}

.selectAll:hover {
  cursor: pointer;
}
.selectAll {
  display: flex;
  align-items: center;
  gap: 4px;
}

.selectAll .iconeSelect {
  font-size: 17px;
}

.selectAll .txtSelectAll {
  text-decoration: underline;
}

.category {
  display: flex;
  gap: 8px;
  align-items: center;
}
.category button {
  min-width: 200px;
}

.newC {
  gap: 8px;
  margin-top: 5px;
  display: flex;
}

.cat_line {
  margin-top: 20px;
  gap: 10px;
  align-items: center;
}

.cat_line select {
  padding: 5px 2px;
}

.footerAdd {
  position: sticky;
  bottom: 0;
  padding: 10px 0;
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.desactivate {
  opacity: 0.4;
}
.desactivate:hover {
  cursor: not-allowed !important;
}
.desactivate > * {
  pointer-events: none !important;
}

.img_media {
  background-size: contain;
  width: 100%;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center center;
}
.galeriescreen .img_media:hover {
  cursor: pointer;
}

.galeriescreen .galerie_title {
  display: none !important;
}

.galeriescreen, .gelerieglobal{
  display: flex;
    height: 100%;
    width: 100%;
}

.gelerieglobal{
  flex-direction: column;
}

.galerieMedia{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row_gallery{
  width: 100%;
  margin: 0 auto;
}
</style>
